<template>
  <div class="base-table-container">
    <!-- 表格过滤 -->
    <div class="filter-box">
      <table-filter v-if="filterList" ref="tableFilter" :filterList="filterList" @filterChange="filterChange"></table-filter>
    </div>
    <!-- 添加插槽 -->
    <slot name="table-top" />
    <!-- 表格 -->
    <div class="table-wrapper">
      <el-table v-on="$listeners" v-bind="$attrs" :data="tableList||list" v-loading="loading" :height="height" @selection-change="handleSelectionChange" @sort-change="handleSortChange" @select="select" @select-all="selectAll" ref="multipleTable">
        <template v-for="(item, i) in columns">
          <!-- 根据角色权限判断当前列是否显示 -->
          <template v-if="item.condition===undefined||item.condition(item)">
            <!-- 单选按钮/多选按钮 -->
            <el-table-column :key="i" v-bind="item.attrs" v-if="item.checkbox" type="selection">
            </el-table-column>
            <el-table-column :key="i" v-bind="item.attrs" v-else-if="item.radio">
              <template slot-scope="scope">
                <el-radio :label="scope.$index" v-model="radio" @change.native="currentRow(scope.row)" style="color: #fff;padding-left: 10px; margin-right: -25px;"></el-radio>
              </template>
            </el-table-column>
            <!-- 操作按钮 直接在生成操作列时 获取item.btns-->
            <el-table-column :key="i" v-bind="item.attrs" v-else-if="item.btns">
              <template slot="header" v-if="item.filter">
                {{item.attrs.label}}
                <filter-table :ref="item.filter.ref" :type="item.filter.type" :filterCondition="item.filter.filterCondition" v-on="item.filter.listeners"></filter-table>
              </template>
              <template slot-scope="scope">
                <template v-for="(btn,key) in item.btns(scope.row)">
                  <el-popover v-if="btn.popover" v-bind="btn.popover.attrs" :ref="`disuser-popover-${scope.$index}`" :key="key">
                    <p>{{btn.msg}}</p>
                    <div style="text-align: right; margin: 10px 0 0 0">
                      <el-button size="mini" type="text" @click="scope._self.$refs[`disuser-popover-${scope.$index}`][0].doClose()">取消</el-button>
                      <el-button type="primary" size="mini" v-on="btn.listeners" @click=" scope._self.$refs[`disuser-popover-${scope.$index}`][0].doClose()">确定</el-button>
                    </div>
                    <i :class="btn.iconclass" slot="reference" v-if="btn.iconclass"></i>
                    <el-button :icon="btn.icon" v-bind="btn.attrs" slot="reference" v-else>{{btn.name}}</el-button>
                  </el-popover>
                  <i v-bind="btn.attrs" v-else-if="btn.iconclass" :class="btn.iconclass" v-on="btn.listeners" :key="key" style="cursor: pointer;"></i>
                  <!-- <el-button
                    v-bind="btn.attrs"
                    v-else-if="btn.button"
                    v-on="btn.listeners"
                    :key="key"
                  >{{btn.button}}
                  </el-button> -->
                  <el-button :class="`table-button ${btn.class}`" v-bind="btn.attrs" v-else-if="btn.condition===undefined||btn.condition(scope.row)" v-on="btn.listeners" :icon="btn.icon" :key="key">{{btn.name}}
                  </el-button>

                </template>
              </template>
            </el-table-column>
            <!-- 状态数据 -->
            <el-table-column :key="i" v-bind="item.attrs" v-else-if="item.statusList">
              <template slot="header" v-if="item.filter">
                {{item.attrs.label}}
                <filter-table :ref="item.filter.ref" :type="item.filter.type" :filterCondition="item.filter.filterCondition" v-on="item.filter.listeners"></filter-table>
              </template>
              <template slot-scope="{row}">
                <template v-for="(subItem,subI) in item.statusList">
                  <span v-if="subItem.value==Boolean(row[item.attrs.prop])" :key="subI" :style="{...subItem.style}">{{subItem.label || row[item.attrs.prop]}}</span>
                </template>
              </template>
            </el-table-column>
            <!-- 多个状态数据 -->
            <el-table-column :key="i" v-bind="item.attrs" v-else-if="item.typeList">
              <template slot="header" v-if="item.filter">
                {{item.attrs.label}}
                <filter-table :ref="item.filter.ref" :type="item.filter.type" :filterCondition="item.filter.filterCondition" v-on="item.filter.listeners"></filter-table>
              </template>
              <template slot-scope="{row}">
                <template v-for="(subItem,subI) in item.typeList">
                  <span v-if="subItem.value==String(row[item.attrs.prop])" :key="subI" :style="{...subItem.style}">{{subItem.label || row[item.attrs.prop]}}</span>
                </template>
              </template>
            </el-table-column>
            <!-- 时间数据 -->
            <el-table-column :key="i" v-bind="item.attrs" v-else-if="item.time">

              <template slot-scope="{row}">
                {{row[item.attrs.prop]?$moment(row[item.attrs.prop]).format(item.time): ''}}
              </template>
            </el-table-column>
            <!-- 展示按钮 -->
            <el-table-column :key="i" v-bind="item.attrs" v-else-if="item.show">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <p>{{item.title}}</p>
                  <p>{{scope.row[item.content]}}</p>
                  <div slot="reference" class="name-wrapper">
                    {{scope.row[item.attrs.prop]}}
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <!-- 基础数据 -->
            <el-table-column :key="i" v-bind="item.attrs" v-else>
              <template slot="header" v-if="item.filter">
                {{item.attrs.label}}
                <filter-table :ref="item.filter.ref" :type="item.filter.type" :filterCondition="item.filter.filterCondition" v-on="item.filter.listeners"></filter-table>
              </template>
            </el-table-column>
          </template>

        </template>
      </el-table>

    </div>

  </div>
</template>

<script>
// 表格上方过滤框
import tableFilter from './tableFilter'
// 表格表头过滤框
import FilterTable from './filterTable'
export default {
  name: 'baseTable',
  components: {
    tableFilter,
    FilterTable
    // tablePagination
  },
  data () {
    return {
      /* 分页参数 */
      page: {
        total: undefined,
        currentPage: 1,
        pageSize: 10
      },
      // 表格加载状态
      loading: false,
      // 筛选参数
      form: {},
      // 列表数据
      list: [],
      radio: null,
      selectedItems: []
    }
  },
  props: {
    /* 表格头 */
    columns: {
      type: Array,
    },
    // 表格数据 若有接收该数据 则用该数据
    tableList: {
      type: Array
    },
    // filter配置项列表（配置过滤条件）
    filterList: {
      type: Array
    },
    // 获取列表的方法
    http: {
      type: Function
    },
    // 获取列表时的固定参数
    defaultParme: {
      type: Object,
      default: null
    },
    // 表格固定高度
    height: {
      type: [Number, String]
    }
  },
  created () {
  },
  methods: {
    /* filter条件发生改变 */
    filterChange (form) {
      this.form = form
      this.page.currentPage = 1
      this.$emit('queryForm', form)
    },
    /* 多选 */
    handleSelectionChange (value) {
      this.$emit('checkboxChange', value)
    },
    // 单选
    currentRow (value) {
      this.$emit('radioChange', value)
    },
    // 排序
    handleSortChange (column) {
      // console.log(column, prop, order)
      if (column) {
        this.$emit('sortChange', column)
      }
    },
    /* filter条件发生改变 */
    search () {
      this.$refs.tableFilter.search()
    },
    /* 重置表格过滤参数 */
    reset () {
      this.$refs.tableFilter.reset()
    },
    //选中table已有数据
    selectedPreExisting (rows) {
      if (rows.length > 0) {
        //这里使用this.$nextTick是防止表格未渲染完成就执行，导致无法添加选中效果
        this.$nextTick(() => {
          rows.forEach(row => {
            //判断row是存在当前tableList
            let selectedItem = this.tableList.find(item => item.id == row.id)
            this.$refs.multipleTable.toggleRowSelection(selectedItem);
          });
        })
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //表格单选
    select (selection, row) {
      //因为翻页之后，点选时selection会出现undefined,所以这里需要进行判断
      //这里可以通过判断选择selection中有没有row，就可以判断出是增加还是删减
      // console.log(selection, row)
      if (selection && selection.find(item => item && (item.id == row.id))) {
        this.addRows([row])
      } else {
        this.deleteRows([row])
      }
    },
    //表格全选
    selectAll (selection) {
      //判断是选中还是取消
      // console.log(selection)
      if (selection.length > 0) {
        this.addRows(this.tableList)
      } else {
        this.deleteRows(this.tableList)
      }
    },
    //添加选中
    addRows (rows) {
      // console.log(rows)

      rows.forEach(row => {
        //过滤，当selectedItems有此条数据时，则直接返回，不执行添加
        if (this.selectedItems.find(item => item.id == row.id)) { return }
        this.selectedItems.push(row)
      });
      this.getCheckboxList()
    },
    //取消选中
    deleteRows (rows) {
      // console.log(rows)

      //当selectedItems为空数组时，不执行删除
      if (this.selectedItems.length == 0) { return }
      rows.forEach(row => {
        this.selectedItems = this.selectedItems.filter(item => item.id !== row.id)
      })
      this.getCheckboxList()

    },
    // 获取已选择的所有数据
    getCheckboxList () {
      this.$emit('checkboxList', this.selectedItems)
    },
    // 重置多选框
    resetCheck () {
      this.selectedItems = []
      this.selectedPreExisting(this.selectedItems)
    }
  }
}
</script>

<style  lang="scss" scoped>
.table-wrapper {
  // padding: 20px;
  background-color: #fff;
}
// .base-table-container {
//   margin: 20px;
// }
.table-button {
  margin-left: 0;
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.btn_red {
  color: red;
}
.btn_green {
  color: #1eb333;
}
.btn_org {
  color: #fd7911;
}
.btn_gray {
  color: #666666;
}
::v-deep .el-popover__reference-wrapper {
  cursor: pointer;
}
::v-deep .el-radio__label {
  display: none;
}
</style>
