<template>
  <div id="main-wrap">
    <div class="main-content">
      <div class="crumbs">
        <el-button type="primary" :icon="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'" size="small" @click="changeCollapse"></el-button>
        <!-- <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
          <el-breadcrumb-item>活动列表</el-breadcrumb-item>
          <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        </el-breadcrumb> -->
        <!-- el-icon-s-fold el-icon-s-unfold-->
      </div>

      <div class="main">
        <el-card v-if="$route.meta.showCard">
          <router-view />
        </el-card>
        <!-- <el-card v-if="$route.meta.showCard"> -->
        <router-view v-else />
        <!-- </el-card> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'layoutMain',
  created () {
    console.log(this.$route)
  },
  computed: {
    // ...mapState(["settings"]),
    ...mapGetters(["isCollapse"])
  },
  methods: {
    ...mapActions(['ACT_ISCOLLAPSE']),
    changeCollapse () {
      this.ACT_ISCOLLAPSE(!this.isCollapse)
    }
  }
}
</script>
<style lang="scss" scoped>
.crumbs {
  width: 100%;
  background: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  ::v-deep .el-button--small {
    padding: 5px 15px;
    margin-right: 20px;
    .el-icon-s-fold,
    .el-icon-s-unfold {
      font-size: 20px;
      font-weight: normal;
    }
  }
}
.main {
  padding: 0 10px;
  .el-card {
    min-height: 65vh;
  }
}
// #main-wrap {
//   // height: 100%;
//   background: none;
// }
// ::v-deep.el-card {
//   height: 100%;
//   // margin-top: 10px;
//   .el-card__header {
//     font-size: 20px;
//     color: #2a2a2a;
//     padding: 0;
//     padding-left: 10px;
//     span {
//       display: inline-block;
//       padding: 19px 10px 17px;
//       border-bottom: 2px #409efe solid;
//     }
//   }
//   .el-card__body {
//     // padding: 0;
//     // padding-bottom: 20px;
//     position: relative;
//     height: 74vh;
//     position: relative;
//     // background: url(../../../assets/box_bg.png) no-repeat right bottom;
//     background-size: 399px 607px;
//     .base-table-container {
//       margin: 0;
//     }
//   }
// }
</style>
