const nav = {
  state: {
    isCollapse: false
  },

  mutations: {
    MUT_ISCOLLAPSE: (state, isCollapse) => {
      state.isCollapse = isCollapse
    }
  },
  actions: {
    // 修改侧边栏展开收缩
    ACT_ISCOLLAPSE ({ commit }, isCollapse) {
      commit('MUT_ISCOLLAPSE', isCollapse)
    }
  }
}

export default nav
