<template>
  <el-popover v-model="isShowFilter" placement="bottom-start" trigger="click" :visible-arrow="false" width="100" class="filterpopover">
    <ul class="filters" v-if="type === 'radio'">
      <li v-for="(item,index) in filterCondition" :key="index" :class="index === number?'activefilter':''" @click="changefilter(index, item.status)">{{item.text}}</li>
    </ul>
    <el-checkbox-group v-else v-model="filterResVal" :max="filterCondition.length">
      <el-checkbox :label="item.label" v-for="(item,index) in filterCondition" :key="index"></el-checkbox>
    </el-checkbox-group>
    <div class="el-table-filter__bottom" v-if="type !== 'radio'">
      <button @click="filterTableData">筛选</button>
      <button @click="refresh">重置</button>
    </div>
    <span slot="reference">
      <!-- <i
        class="el-icon-sort"
        style="cursor: pointer;"
        slot="reference"
      ></i> -->
      <i class="filter_icon"><img src="./filter.png" alt=""></i>
    </span>
  </el-popover>
</template>

<script>
export default {
  props: {
    filterCondition: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'check'
    }
  },
  data () {
    return {
      isShowFilter: false,    //控制隐藏过滤弹窗
      filterResVal: [],			//存储选项值
      filterResKey: [],
      number: 0
    };
  },
  // watch: {
  //   filterCondition () {
  //     console.log(this.filterCondition)
  //   }
  // },
  created () {
    // console.log(this.filterCondition)
  },
  mounted () {
    // console.log(this.filterCondition)
  },

  methods: {
    init (val) {
      this.filterCondition = val
    },
    filterTableData () {
      // console.log(this.filterResVal)
      this.filterResKey = [];   //每次筛选重置筛选选项值
      this.filterCondition.forEach(item => {
        if (this.filterResVal.length) {
          this.filterResVal.forEach(items => {
            // console.log(item, items)
            if (item.label === items) {
              this.filterResKey.push(item.key);
            }
          })
        }
      });
      let value = '';
      if (this.filterResKey.length) {
        value = this.filterResKey.join(',');
      }
      this.isShowFilter = false;
      this.$emit('filteredData', value);  //将选项值转为字符串传给父组件
    },
    refresh () {
      this.isShowFilter = false
      this.filterResKey = []
      this.filterResVal = []
      this.$emit('filteredData', this.filterResKey)
    },
    changefilter (index, status) {
      this.number = index
      this.isShowFilter = false
      this.$emit('filteredData', status)
    }
  }
};
</script>

<style scoped lang="scss">
.el-checkbox-group {
  display: flex;
  flex-direction: column;
}
.el-checkbox {
  padding: 8px 10px;
}

.filters {
  // font-size: 16px;
  color: #333;
  padding: 0;
  li {
    padding: 6px 10px;
    margin: 1px;
    cursor: pointer;
    list-style: none;
  }
  li:hover {
    background: rgba(236, 245, 255, 0.5);
    color: #409eff;
  }
  .activefilter {
    color: white;
    background: #409eff;
  }
  .activefilter:hover {
    color: white;
    background: #409eff;
  }
}
.filter_icon {
  display: inline-block;
  width: 14px;
  // padding-top: 10px;
  img {
    width: 100%;
  }
}
</style>
