import request from '@/utils/request'
import qs from 'qs'
// 登录
const login = (params) => request.get(`/login?${qs.stringify(params)}`, {
  auth: {
    username: "webApp",
    password: 'webApp',
  }
})
// 获取验证码
const logincode = (params) => request.get('/login/sendMessage', {
  params
})

/* 权限管理 */
// 获取角色列表
const getRoleList = () => request.get('/sysmanagement/authority/role/get')
// 新增角色
const addRole = (params) => request.post('/sysmanagement/authority/role/add', params)
// 删除角色
const deleteRole = (params) => request.post('/sysmanagement/authority/role/delete', params)
// 获取各角色的权限
const getPermissionList = (params) => request.get('/sysmanagement/authority/rolemenu/get', { params })
// 获取所有权限
const getPermission = () => request.get('/sysmanagement/authority/menuTree/get')
// 修改权限
const editPermission = (params) => request.post('/sysmanagement/authority/rolemenu/update', params)

/* 人员管理 */
// 人员列表
const getPersonList = (params) => request.get('/sysmanagement/account/get', { params })
// 供应商人员列表
const getSupplier = (params) => request.get('/sysmanagement/account/supplier', { params })
// 添加人员
const addPerson = (params) => request.post('/sysmanagement/account/add', params)
// 人员删除
const deletePerson = (params) => request.post('/sysmanagement/account/delete', params)

/* 单位管理 */
// 所属单位列表
const getAffiliation = (params) => request.get('/cascadedlocation/schoolAndUnit/get', { params })
// 获取学校单位列表
const getSchoolList = (params) => request.get('/dept/school/get', { params })
// 点位列表
const getPointList = (params) => request.get('/region/list', { params })
// 新增点位
const addPoint = (token, params) => request.post(`/region?access_token=${token}`, params, {
  headers: {
    'Content-Type': 'application/json'
  }
})
// 编辑点位
const editPoint = (token, params) => request.put(`/region?access_token=${token}`, params, {
  headers: {
    'Content-Type': 'application/json'
  }
})
// 删除点位
const deletePoint = (params) => request.delete('/region', { params })
// 新增单位
const addUnit = (params) => request.post('/dept/school/add', params)
// 删除单位
const deleteUnit = (params) => request.post('/dept/school/delete', params)
// 编辑单位
const editUnit = (params) => request.put('/dept/school/update', params)

/* 消毒记录 */
const getSterilizeList = (params) => request.get('/clockIn/getClockInInfo', { params })

/* 报警管理 */
// 当前列表
// 历史列表
const getAlarmList = (params) => request.get('/querymanagement/processquery/get', { params })
// 事件处理获取图片id
const getImgId = (token, params) => request.post(`/querymanagement/flowalert/current/uploadImg?access_token=${token}`, params, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
// 事件处理
const updateAlarm = (params) => request.post('/querymanagement/processquery/update', params)

/* 日志管理 */
// 日志管理列表
const getLogList = (params) => request.get('https://ivatest.terabits.cn:9011/log/operate', { params })
// 添加日志
const addLog = (params) => request.post('https://ivatest.terabits.cn:9011/log/operate', params, {
  headers: {
    'Content-Type': 'application/json'
  }
})

/* 供应商管理 */
// 供应商列表
const getSupplierList = (params) => request.get('/dept/supplier/list', { params })
// 根据id获取供应商信息
const getSupplierItem = (params) => request.get('/dept/supplier', { params })
// 新增供应商
const addSupplier = (token, params) => request.post(`/dept/supplier?access_token=${token}`, params, {
  headers: {
    'Content-Type': 'application/json'
  }
})
// 删除供应商
const deleteSupplier = (params) => request.delete('/dept/supplier', { params })
// 编辑供应商
const editSupplier = (token, params) => request.put(`/dept/supplier?access_token=${token}`, params, {
  headers: {
    'Content-Type': 'application/json'
  }
})

/* 设备管理 */
// 设备列表
const getdeviceList = (params) => request.get('/devicemanagement/device/get', { params })
// 滤芯更换记录列表
const getChangeList = (params) => request.get('/devicemanagement/device/change/get', { params })
// 更换滤芯
const changeFilter = (params) => request.post('/querymanagement/change', params)
// 查看检测报告
const getCheckReport = (params) => request.get('/devicemanagement/certificate/report/getlatest', { params })
// 新建检测报告图片上传
const getReportImg = (token, params) => request.post(`/devicemanagement/certificate/report/img/upload?access_token=${token}`, params, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
// 新建检测报告
const addCheckReport = (params) => request.post('/devicemanagement/certificate/report/add', params)
// 查看检测报告历史记录
const getHistoryReport = (params) => request.get('/devicemanagement/certificate/report/gethistorical', { params })
// 查看批件
const getInstruction = (params) => request.get('/devicemanagement/certificate/approval/get', { params })

// 新增设备获取图片id
const getDeviceImg = (token, params) => request.post(`/devicemanagement/device/approval/img/upload?access_token=${token}`, params, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
// 新增设备添加批件
const addApproval = (params) => request.post('/devicemanagement/device/approval/add', params)
// 添加设备获取维修报警信息
const getDeviceInfo = (params) => request.post('/devicemanagement/device/add/getrespersoninfo', params)
// 添加设备
const addDevice = (params) => request.post('/devicemanagement/device/add', params)
// 删除设备
const deleteDevice = (params) => request.post('/devicemanagement/device/delete', params)

export {
  login,
  logincode,
  getRoleList,
  addRole,
  deleteRole,
  getPermissionList,
  getPermission,
  editPermission,
  getPersonList,
  getAffiliation,
  addPerson,
  deletePerson,
  getSterilizeList,
  getAlarmList,
  getLogList,
  addLog,
  getImgId,
  getSupplierList,
  addSupplier,
  deleteSupplier,
  getSchoolList,
  getdeviceList,
  getChangeList,
  editSupplier,
  getSupplierItem,
  getCheckReport,
  updateAlarm,
  getDeviceImg,
  addApproval,
  getPointList,
  addPoint,
  editPoint,
  deletePoint,
  getDeviceInfo,
  addDevice,
  deleteDevice,
  addUnit,
  deleteUnit,
  editUnit,
  changeFilter,
  getInstruction,
  getReportImg,
  addCheckReport,
  getHistoryReport,
  getSupplier
}
