import { constantRoutes } from '@/router'
import { getRoleList, getAffiliation } from '@/api'

// import Layout from '@/layout'
export default {
  namespaced: true,
  state: {
    router: [],
    roles: [],
    typeList: [],
    unitList: []
  },
  mutations: {
    setRouter (state, payload) {
      state.router = payload
    },
    setRoles (state, payload) {
      state.roles = payload
    },
    SET_TYPE: (state, type) => {
      state.typeList = type
    },
    SET_UNIT: (state, unit) => {
      state.unitList = unit
    }
  },
  actions: {
    filterRouters ({ commit }, roles) {
      constantRoutes.map(el => {
        if (el.children && el.children.length > 0) {
          el.children.map(single => {
            if (single.children && single.children.length > 0) {
              single.children.map(only => {
                if (roles.some(item => item.name === only.meta.title)) {
                  only.hidden = false
                } else {
                  only.hidden = true
                }
              })
              single.children.map(only => {
                if (!only.hidden) {
                  single.hidden = false
                } else {
                  single.hidden = true
                }
              })
            } else {
              if (roles.some(item => item.name === single.meta.title)) {
                single.hidden = false
              } else {
                single.hidden = true
              }
            }
          })
        } else {
          if (roles.some(item => item.name === el.meta.title)) {
            el.hidden = false
          } else {
            el.hidden = true
          }
        }
        // roles.map(item => {
        //   if (item.name === el.meta.title) {
        //     return el
        //   }
        // })
      })
      // console.log('routers :>> ', constantRoutes);

      // const dealRouters = routers.map(el => {
      //   return {
      //     component: Layout,
      //     children: [
      //       {
      //         path: el.path,
      //         component: el.component,
      //         meta: el.meta,
      //         hidden: el.hidden
      //       }
      //     ]
      //   }
      // })
      // // constantRoutes
      // // 动态添加路由
      // router.addRoutes([...dealRouters, { path: '*', redirect: '/404', hidden: true }])
      commit('setRouter', [...constantRoutes])
      commit('setRoles', roles)
    },
    // 角色列表
    actionRole ({ commit }) {
      getRoleList().then(res => {
        commit('SET_TYPE', res)
      })
    },
    actionUnit ({ commit }) {
      // 单位列表（全）
      getAffiliation({ adminId: 130 }).then(res => {
        commit('SET_UNIT', res[0].children[0].children[0].children)
      })
    }

  }
}
