import axios from 'axios'
import store from '@/store'
import { Message, MessageBox } from 'element-ui'
import qs from 'qs'
import { getToken } from '@/utils/auth'
// import errorCode from '@/utils/errorCode'

axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://watersupervision.terabits.cn:9091',
  // baseURL: 'http://192.168.1.246:9595',
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // console.log('config :>> ', config);
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  if (getToken()) {
    // config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    if (config.method === 'post' || config.method === 'put') {
      if (!config.headers['Content-Type']) {
        let data = qs.parse(config.data)
        config.data = qs.stringify({
          access_token: getToken(),
          ...data
        })
      }

      // let url = config.url + `?access_token=${getToken()}`
      // if (config.data) {
      //   for (const propName of Object.keys(config.data)) {
      //     const value = config.data[propName];
      //     var part = encodeURIComponent(propName) + "=";
      //     if (value !== null && typeof (value) !== "undefined") {
      //       if (typeof value === 'object') {
      //         for (const key of Object.keys(value)) {
      //           if (value[key] !== null && typeof (value[key]) !== 'undefined') {
      //             let params = propName + '[' + key + ']';
      //             let subPart = encodeURIComponent(params) + '=';
      //             url += subPart + encodeURIComponent(value[key]) + '&';
      //           }
      //         }
      //       } else {
      //         url += part + encodeURIComponent(value) + "&";
      //       }
      //     }
      //   }

      // }
      // config.url = url
    } else if (config.method === 'get' || config.method === 'delete') {
      // console.log('config :>> ', config);
      const obj = config.params
      for (var key in obj) {
        if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
          delete obj[key]
        }
      }
      config.params = {
        access_token: getToken(),
        ...obj
      }
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  if (res.data.status === 1) {
    return res.data.data
  } else if (res.data.status === 200) {
    return res.data
  } else {
    Message({
      message: res.data.message,
      type: 'error'
    })
    return Promise.reject(new Error(res.data.message))
  }
  // const msg = errorCode[code] || res.data.msg || res.data.message || errorCode['default']
  // if (code === 401) {
  //   MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
  //     confirmButtonText: '重新登录',
  //     cancelButtonText: '取消',
  //     type: 'warning'
  //   }
  //   ).then(() => {
  //     store.dispatch('FedLogOut').then(() => {
  //       location.href = '/login';
  //     })
  //   }).catch(() => { });
  //   return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  // } else if (code === 500 || code === 1003) {
  //   Message({
  //     message: msg,
  //     type: 'error'
  //   })
  //   return Promise.reject(new Error(msg))
  // } else if (code !== 200 && code !== 1) {
  //   // Notification.error({
  //   //   title: msg
  //   // })
  //   Message({
  //     message: msg,
  //     type: 'error'
  //   })
  //   return Promise.reject('error')
  // } else {
  //   return res.data
  // }
},
  error => {
    let { message } = error;
    let hintMsg = ''
    if (message == "Network Error") {
      hintMsg = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      hintMsg = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      hintMsg = "系统接口" + message.substr(message.length - 3) + "异常";
      if (message.substr(message.length - 3) === '401') {
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
        ).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.href = '/login';
          })
        }).catch(() => { });
        return
      }
    }
    Message({
      message: hintMsg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
