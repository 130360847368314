const getters = {
  token: state => state.user.token,
  isCollapse: state => state.nav.isCollapse,
  realname: state => state.user.realname,
  username: state => state.user.username,
  cascadedlocation: state => state.user.cascadedlocation,
  menu: state => state.user.menu,
  type: state => state.user.type,
  roles: state => state.permission.roles,
  router: state => state.permission.router,
  typeList: state => state.permission.typeList,
  unitList: state => state.permission.unitList
}
export default getters
