<template>
  <el-pagination :background="background" :page-size.sync="pageSize" :current-page.sync="currentPage" :layout="layout" :page-sizes="pageSizes" :pager-count="pagerCount" :total="total" :prev-text="prevText" :next-text="nextText" v-bind="$attrs" @size-change="handleSizeChange" @current-change="handleCurrentChange">
    <slot></slot>
  </el-pagination>
</template>

<script>
export default {
  name: 'basePagination',
  props: {
    // < 上一页文字
    prevText: {
      type: String
    },
    // > 下一页文字
    nextText: {
      type: String
    },
    // 总量
    total: {
      required: true,
      type: Number
    },
    // 当前页
    page: {
      type: Number,
      default: 1
    },
    // 一页显示几条
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default () {
        return [10, 20, 30, 50]
      }
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7
    },
    layout: {
      type: String,
      default: 'sizes, prev, pager, next, jumper, ->, total, slot'
    },
    background: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentPage: {
      get () {
        return this.page
      },
      set (val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get () {
        return this.limit
      },
      set (val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange (val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
    },
    handleCurrentChange (val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style  lang="scss" scoped>
.el-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
