<template>
  <div class="images-list">
    <el-upload class="upload-demo" :action="action" :headers="headers" name="multipartFile" :accept="accept" :on-preview="handlePreview" :on-remove="handleRemove" :on-success="handleSuccess" :on-error="handleUploadError" :before-upload="handleBeforeUpload" :http-request="httpRequest" :on-exceed="handleExceed" :file-list="fileList" :multiple="multiple" :limit="fileLimit" :list-type="listType">
      <!-- <i v-if="listType === 'picture-card'" class="el-icon-plus"></i>
      <el-button v-else size="small" type="primary">点击上传</el-button>
      <div v-if="showTip" slot="tip" class="el-upload__tip">只能上传{{accept || 'jpg/png'}}文件，且不超过 {{fileSize}}MB</div> -->
      <el-button class="uploadbtn" size="small">
        <i class="el-icon-upload"></i>
        <p>{{uploadText}}</p>
      </el-button>
    </el-upload>
  </div>
</template>
<script>
export default {
  name: 'baseUpload',
  props: {
    // 上传的请求头部
    headers: {
      type: Object,
      default: () => ({
        'Content-Type': 'multipart/form-data'
      })
    },
    // 上传是否支持多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 上传文件类型
    accept: {
      type: String,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件列表类型 text/picture/picture-card
    listType: {
      type: String,
      default: 'picture'
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    // 最大允许上传个数
    fileLimit: {
      type: Number,
      default: 99
    },
    // 数量超过限制
    limitMsg: {
      type: String,
      default: '超出上传文件个数,请删除以后再上传！'
    },
    // 图片列表
    fileList: {
      type: Array,
      default: () => []
    },
    // 单张图片
    file: {
      type: Object,
      default: () => { }
    },
    // 上传地址
    action: {
      type: String,
      default: '#'
    },
    // 上传按钮显示文字
    uploadText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uploadUrl: "https://xxxxxxxxxxxxxxxxxxx/upload", // 上传的图片服务器地址
      // tempFileList: [] // 因为 fileList为只读属性，所以用了一个中间变量来进行数据改变的交互。
    }
  },
  // watch: {
  //   value: {
  //     handler: function (newVal) {
  //       this.tempFileList = newVal
  //     },
  //     immediate: true,
  //     deep: true
  //   }
  // },
  computed: {
    // 是否显示提示
    showTip () {
      return this.isShowTip && (this.accept || this.fileSize);
    }
  },
  created () {
    // this.fileList = JSON.parse(JSON.stringify(this.value))
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload (file) {
      const that = this
      let Orientation
      let ndata
      // 判断大小是否需要压缩
      if (file.size <= 1 * 1024 * 1024) {
        // 判断图片是否大于1M,是就直接上传

        return true
      } else {
        // 反之压缩图片
        return new Promise(resolve => {
          const reader = new FileReader()
          // // 将图片2将转成 base64 格式
          reader.readAsDataURL(file)
          // // 读取成功后的回调
          reader.onloadend = function () {
            const result = this.result
            const img = new Image()
            img.src = result
            img.onload = function () {
              const data = that.compress(img, Orientation)
              that.headerImage = data
              ndata = that.compress(img, Orientation)

              // BASE64转图片
              const arr = ndata.split(','); const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1]); let n = bstr.length; const u8arr = new Uint8Array(n)
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
              }
              ndata = new File([u8arr], file.name, { type: mime })
              resolve(ndata)
            }
          }

        })

        // return false
      }
    },
    // 压缩图片
    compress (img, Orientation) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 瓦片canvas
      const tCanvas = document.createElement('canvas')
      const tctx = tCanvas.getContext('2d')
      let width = img.width
      let height = img.height
      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio
      if ((ratio = width * height / 4000000) > 1) {
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
      } else {
        ratio = 1
      }
      canvas.width = width
      canvas.height = height
      // 铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // 如果图片像素大于100万则使用瓦片绘制
      let count
      if ((count = width * height / 1000000) > 1) {
        count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
        //            计算每块瓦片的宽和高
        const nw = ~~(width / count)
        const nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height)
      }
      // 修复ios上传图片的时候 被旋转的问题
      if (Orientation !== '' && Orientation !== 1) {
        switch (Orientation) {
          case 6:// 需要顺时针（向左）90度旋转
            this.rotateImg(img, 'left', canvas)
            break
          case 8:// 需要逆时针（向右）90度旋转
            this.rotateImg(img, 'right', canvas)
            break
          case 3:// 需要180度旋转
            this.rotateImg(img, 'right', canvas)// 转两次
            this.rotateImg(img, 'right', canvas)
            break
        }
      }
      // 进行最小压缩
      const ndata = canvas.toDataURL('image/jpeg', 0.1)
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0

      return ndata
    },
    // 覆盖默认的上传行为，可以自定义上传的实现
    httpRequest (data) {
      // console.log(data, this.fileList)
      this.$emit("update:file", data)
      this.$emit('upload', data)
    },

    handleUploadError () {
      this.$message.error("上传失败, 请重试");
    },
    // 文件个数超出
    handleExceed () {
      this.$message.error(this.limitMsg);
    },
    // 文件上传成功的钩子
    handleSuccess (res, file, fileList) {
      this.$message.success("上传成功")
      this.changeFileList(fileList)
    },
    // 文件列表移除文件时的钩子
    handleRemove (file, fileList) {
      // console.log(file)
      this.$emit('remove', file)
      this.changeFileList(fileList)
    },
    // 文件列表改变的时候，更新组件的v-model的文的数据
    changeFileList (fileList) {
      // const tempFileList = fileList.map(item => {
      //   let tempItem = {
      //     name: item.name,
      //     url: item.response ? item.response.url : item.url
      //   }
      //   return tempItem
      // })
      this.$emit("update:fileList", fileList)
    },
    // 预览上传图片
    handlePreview (file) {
      this.$emit('handlePreview', file.url)
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .uploadbtn {
  > span {
    display: flex;
    align-items: center;
    .el-icon-upload {
      font-size: 22px;
      margin-right: 10px;
    }
  }
}
// .images-list {
//   border: 1px dashed #d5d5d5;
//   padding: 10px;
//   border-radius: 4px;
//   background: #fff;
// }
</style>
