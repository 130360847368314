<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu :default-active="activeMenu" :unique-opened="true" mode="vertical" :collapse="isCollapse">
      <sidebar-item v-for="(route, index) in constantRoutes" :key="route.path  + index" :item="route" :base-path="route.path" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
// import { mapGetters } from "vuex";
// import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
// import { constantRoutes } from '@/router'
import { mapGetters } from 'vuex'
// import variables from "@/assets/styles/variables.scss";

export default {
  data () {
    return {
      // isCollapse: true
    }
  },
  components: { SidebarItem },
  computed: {
    // ...mapState(["settings"]),
    ...mapGetters(["isCollapse", 'router', 'token']),
    activeMenu () {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    constantRoutes () {
      console.log('object :>> ', this.router);
      // console.log('constantRoutes :>> ', this.router.filter(el => el.children)[0].children);
      let routers = JSON.parse(JSON.stringify(this.router.filter(el => el.children)[0].children))
      routers.unshift({
        path: `https://visual.terabits.cn/water?token=${this.token}`,
        name: 'dashboard',
        hidden: false,
        meta: { title: '仪表盘', icon: 'icon-yibiaopan' }
      })
      // console.log('object :>> ', this.router);
      return routers
    }
    // variables() {
    //     return variables;
    // },
    // isCollapse () {
    //   return !this.sidebar.opened;
    // }
  },
  watch: {
    $route: {
      handler: (route) => {
        console.log(route)
      },
      immediate: true
    }
  },
  // methods: {
  //   select () {
  //     console.log('val :>> ', this.$router);
  //   }
  // }
};
</script>
<style scoped lang="scss">
// .scrollbar-wrapper {
//   max-height: 100vh;
// }
</style>
