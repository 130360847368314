// 添加日志
import { addLog } from '@/api'

function add (query) {
  addLog(query).then(() => {
    return
  })
}

export default add
