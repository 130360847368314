<template>
  <div class="navbar">
    <div class="left">
      <img src="@/assets/header.png" alt="" srcset="">
    </div>
    <div class="right">
      <p>
        <span>{{this.$moment().format('YYYY年MM月DD日')}}</span>
        <span class="time">{{this.$moment(time).format('HH:mm:ss')}}</span>
      </p>
      <p class="user">
        欢迎您，
        <i class="el-icon-user"></i>
        <span>{{realname}}</span>
      </p>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-s-unfold" style="cursor: pointer;"></i>
          退出
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      time: this.$moment(),
      interval: null
    }
  },
  computed: {
    ...mapGetters(['realname'])
  },
  created () {
    this.interval = setInterval(() => this.time = new Date(), 1000);

  },
  methods: {
    // 退出登录
    handleCommand () {
      this.$confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('FedLogOut').then(() => {
          // window.sessionStorage.setItem("activerouter", '/quality')
          location.href = '/login';
        })
      }).catch(() => { });
    },
    // toggleSideBar() {
    //   this.$store.dispatch('app/toggleSideBar')
    // },
    // async logout() {
    //   this.$confirm('确定注销并退出系统吗？', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$store.dispatch('LogOut').then(() => {
    //       location.href = '/index';
    //     })
    //   }).catch(() => {});
    // }
  },
  destroyed () {
    clearInterval(this.interval)
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  .left {
    width: 500px;
    img {
      width: 100%;
    }
  }
  .right {
    color: #fff;
    display: flex;

    .time {
      margin: 0 10px 0 5px;
      font-size: 16px;
    }
    .user {
      font-size: 16px;
      margin-right: 8px;
    }
    span {
      font-size: 16px;
    }
    .el-dropdown {
      font-size: 16px;
      cursor: pointer;
      color: #fff;
      // .el-icon-s-unfold {
      //   color: #fff;
      // }
    }

    // font-size: 16px;
  }
}
</style>
