import router from './router'
import store from './store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
NProgress.configure({ showSpinner: false })

const whiteList = ['/login']
console.log('store :>> ', store.dispatch);
router.beforeEach((to, from, next) => {
  NProgress.start()
  // console.log('getToken() :>> ', getToken());
  if (getToken()) {
    /* has token*/
    // console.log('to :>> ', to, from);
    if (to.path === '/login') {
      next()
      NProgress.done()
    } else {
      if (store.getters.router.length === 0) {
        store.dispatch('permission/filterRouters', store.getters.menu)
        store.dispatch('permission/actionRole')
        store.dispatch('permission/actionUnit')
        // store.dispatch('user/GetUserList')
        next(to.path)
      } else {
        next()
      }
      //       if (to.path === '/device' && from.path==='/') {
      //  //   store.dispatch('permission/filterRouters', store.getters.menu)
      //       //   store.dispatch('permission/actionRole')
      //       //   store.dispatch('permission/actionUnit')
      //       //   // store.dispatch('user/GetUserList')
      //       //   next(to.path)
      //       } else {
      //         next()
      //       }
      // console.log(' :>> ', store.getters.menu);

    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
  // }

})

router.afterEach(() => {
  NProgress.done()
})
