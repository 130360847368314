import BasePagination from './Pagination/index.vue'
import BaseTable from './baseTable/baseTable.vue'
import BaseUpload from './baseUpload'
// import BaseCamera from './baseCamera'
export default {
  install (Vue) {
    // 注册全局组件
    Vue.component('BasePagination', BasePagination)
    Vue.component('BaseTable', BaseTable)
    Vue.component('BaseUpload', BaseUpload)
    // Vue.component('BaseCamera', BaseCamera)
  }
}
