<template>
  <div
    class="filter-wrapper"
    v-if="formList.length"
  >
    <el-form
      ref="form"
      :inline="true"
      v-bind="$attrs"
    >
      <template v-for="(item, key) in formList">
        <template v-if="item.condition===undefined||item.condition(item)">
          <!-- 文本输入框 -->
          <el-form-item
            :label="item.name"
            :key="key"
            v-if="item.type == 'input'"
          >
            <el-input
              v-model="item.value"
              v-on="item.listeners"
              v-bind="item.attrs"
              clearable
              @change="sendForm"
            >
              <template v-if="item.slot">
                <el-button
                  v-if="item.slotType === 'button'"
                  :slot="item.slot"
                  :icon="item.icon"
                  @click="sendForm"
                ></el-button>
                <span v-if="item.slotType==='text'">{{item.text}}</span>
              </template>
            </el-input>
          </el-form-item>
          <!-- select选择器 -->
          <el-form-item
            :label="item.name"
            :key="key"
            v-if="item.type == 'select'"
          >
            <el-select
              v-model="item.value"
              v-on="item.listeners"
              v-bind="item.attrs"
              clearable
              @change="sendForm"
            >
              <!-- <el-option
                label="全部"
                value=""
              >
              </el-option> -->
              <el-option
                v-for="sub in item.options"
                :key="sub[item.props?item.props.value:'value']"
                :label="sub[item.props?item.props.label:'label']"
                :value="sub[item.props?item.props.value:'value']"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 日期选择器 -->
          <el-form-item
            :label="item.name"
            :key="key"
            v-if="item.type == 'date'"
          >
            <el-date-picker
              clearable
              v-model="item.value"
              v-on="item.listeners"
              v-bind="item.attrs"
              :picker-options="pickerOptions"
              @change="sendForm"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              range-separator="-"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 级联选择器 -->
          <el-form-item
            :label="item.name"
            :key="key"
            v-if="item.type == 'cascader'"
          >
            <el-cascader
              clearable
              v-model="item.value"
              v-on="item.listeners"
              v-bind="item.attrs"
              :options="item.options"
              :props="item.props"
              @change="sendForm"
            >
            </el-cascader>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item
            :key="key"
            v-if="item.type == 'button'"
          >
            <el-button
              v-bind="item.attrs"
              v-on="item.listeners"
            >{{ item.name }}</el-button>
          </el-form-item>
        </template>

      </template>
    </el-form>
  </div>
</template>
 
<script>
export default {
  name: "TableFilter",
  data () {
    return {
      formList: [],//表单数据
      pickerOptions: {//日期选择器参数
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

    };
  },
  props: {
    filterList: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  created () {
    /* 获取筛选项列表  用于数据的动态绑定 */
    this.init()
  },
  methods: {
    /* 重置 */
    reset () {
      this.init()
      this.sendForm()
    },
    // 搜索 查询
    search () {
      this.sendForm()
    },
    /* 初始化数据 */
    init () {
      this.$set(this, 'formList', this.filterList)
      this.formList.forEach((item, i) => {
        if (item.type === 'range') {
          this.$set(this.formList[i], 'value', ['', ''])
          return
        }
        this.$set(this.formList[i], 'value', '')
      })
    },
    /* 筛选条件发生变化  input输入不会触发该事件  但是input后点击enter键会触发 */
    sendForm () {
      let form = {}
      this.formList.forEach(item => {
        if (item.value) {
          if (item.type == 'date' && item.props) {
            if (item.value[0]) {
              form[item.props.start] = item.value[0]
            }
            if (item.value[1]) {
              form[item.props.end] = item.value[1]
            }
            return
          }
          if (item.type == 'date' && item.prop) {
            if (item.value[0]) {
              form[item.prop] = item.value
            }
            return
          }
          // 返回的数据是数组 但是后台需要用多个字段接收  因此遍历配置的 prop 分别接收value（数组）中的值
          if (item.type == "cascader") {
            if (item.fieldNames) {
              item.fieldNames.forEach((lable, index) => {
                if (item.value[index]) {
                  form[lable] = item.value[index]
                }
              })
              return
            }
          }
          form[item.prop] = item.value
        }
      })
      // 筛选条件发生变化 暴露filterChange方法，并返回参数form(当前的筛选条件)
      this.$emit('filterChange', form)
    }
  },
};
</script>
 
<style scoped lang="scss">
.filter-wrapper {
  padding: 20px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>
