import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

// 公共路由
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: { title: '登录' },
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/device',
    hidden: true,
    children: [
      {
        path: '/unit',
        component: () => import('@/views/unit'),
        name: 'unit',
        hidden: false,
        meta: { title: '单位管理', icon: 'icon-danweiguanli', showCard: true }
      },

      {
        path: '/device',
        hidden: false,
        component: () => import('@/views/device'),
        name: 'device',
        meta: { title: '设备管理', icon: 'icon-shebeiguanli' }
      },
      {
        path: '/alarm',
        hidden: false,
        component: () => import('@/views/alarm'),
        name: 'alarm',
        meta: { title: '报警管理', icon: 'icon-baojingguanli' }
      },
      {
        path: '/system',
        component: () => import('@/views/system'),
        hidden: false,
        name: 'system',
        meta: { title: '系统管理', icon: 'icon-xitongguanli', showCard: true },
        children: [
          {
            path: 'authority',
            component: () => import('@/views/system/authority'),
            hidden: false,
            name: 'authority',
            meta: { title: '权限管理', showCard: true }
          },
          {
            path: 'log',
            component: () => import('@/views/system/log'),
            hidden: false,
            name: 'log',
            meta: { title: '日志管理', showCard: true }
          },
          {
            path: 'personnel',
            hidden: false,
            component: () => import('@/views/system/peopleManagement'),
            name: 'personnel',
            meta: { title: '人员管理', showCard: false }
          },
          {
            path: 'supplier',
            hidden: false,
            component: () => import('@/views/system/supplier'),
            name: 'supplier',
            meta: { title: '供应商管理', showCard: true }
          }
        ]
      },
      {
        path: '/disinfect',
        hidden: false,
        component: () => import('@/views/disinfect'),
        name: 'disinfect',
        meta: { title: '消毒记录', icon: 'icon-xiaodu', showCard: true }
      },
      {
        path: '/addunit',
        component: () => import('@/views/unit/components/addUnit'),
        name: 'addunit',
        hidden: true,
        meta: { title: '添加单位', showCard: true }
      },
      {
        path: '/allot',
        hidden: true,
        component: () => import('@/views/system/roleAllot'),
        name: 'allot',
        meta: { title: '权限分配', showCard: true }
      },
      {
        path: '/addperson',
        hidden: true,
        component: () => import('@/views/system/addperson'),
        name: 'addperson',
        meta: { title: '新建账号', showCard: true }
      },
      {
        path: '/adddevice',
        hidden: true,
        component: () => import('@/views/device/addDevice'),
        name: 'adddevice',
        meta: { title: '新建设备', showCard: true }
      }
    ]
  },

]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
