<template>
  <div class='layout'>
    <el-container>
      <el-header>
        <lay-header />
      </el-header>
      <el-container>
        <el-aside width="auto">
          <lay-nav />
        </el-aside>
        <el-main>
          <lay-main />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import LayHeader from './components/header.vue'
import LayNav from './components/Sidebar'
import LayMain from './components/main.vue'
export default {
  components: { LayHeader, LayNav, LayMain },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.layout {
  .el-header {
    background: #001529;
    height: 80px !important;
  }
  .el-aside {
    height: calc(100vh - 80px);
    background: #001529;
  }
  .el-main {
    padding: 0;
  }
}
</style>
