import { login, getRoleList, addLog } from '@/api'
import { getToken, setToken, removeToken } from '@/utils/auth'
import moment from 'moment'

const user = {
  state: {
    token: getToken(),
    realname: '',
    username: '',
    cascadedlocation: [],
    menu:
      [],
    type: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REALNAME: (state, name) => {
      state.realname = name
    },
    SET_USERNAME: (state, name) => {
      state.username = name
    },
    SET_UNIT: (state, unit) => {
      state.cascadedlocation = unit
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
    },
    SET_TYPE: (state, type) => {
      state.type = type
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          setToken(res.access_token)
          commit('SET_TOKEN', res.access_token)
          commit('SET_REALNAME', res.realname)
          commit('SET_USERNAME', res.username)
          commit('SET_TYPE', res.type)
          commit('SET_MENU', res.menu)
          commit('SET_UNIT', res.cascadedlocation[0].children[0].children[0].children)
          addLog({
            userName: res.realname,
            module: '登录模块',
            content: `${res.realname}登录了平台`,
            type: 1,
            platform: 8,
            operateTime: moment().format('YYYY-MM-DD HH:mm:ss')
          }).then(() => {

          })
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户类别
    GetUserList ({ commit }) {
      return new Promise((resolve, reject) => {
        getRoleList().then(res => {
          commit('SET_TYPE', res)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 前端 登出
    FedLogOut () {
      return new Promise(resolve => {
        localStorage.clear()
        removeToken()
        resolve()
      })
    }
  }
}

export default user
