import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible/flexible'
import moment from "moment";
import Fragment from 'vue-fragment'
import Components from './components/index'
import validator from './utils/validator'
import add from './utils/addLog'
import './permission'

import './style/main.scss'
Vue.config.productionTip = false;
Vue.prototype.$moment = moment
Vue.prototype.$validator = validator
Vue.prototype.$add = add
Vue.use(ElementUI).use(Fragment.Plugin).use(Components);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
